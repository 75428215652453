import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { api } from "../../api/api";

export const getCompanies = createAsyncThunk(
  "companies/getCompanies",
  async (_, thunkApi) => {
    const { token } = await thunkApi.getState().auth;
    const { data } = await api.getCompanies({ x_token: token });
    return data;
  }
);

export const syncCompany = createAsyncThunk(
  "companies/syncCompany",
  async (_, thunkApi) => {
    const { token } = await thunkApi.getState().auth;
    const { currentId } = await thunkApi.getState().companies;
    const { data } = await api.syncCompany({
      x_token: token,
      company_id: currentId,
    });
    return data;
  }
);

// tables
export const getFloors = createAsyncThunk(
  "companies/getFloors",
  async (_, thunkApi) => {
    const { token } = await thunkApi.getState().auth;
    const { currentId } = await thunkApi.getState().companies;
    const { data } = await api.getFloors({
      x_token: token,
      company_id: currentId,
    });
    return data;
  }
);
export const updateFloor = createAsyncThunk(
  "companies/updateFloor",
  async (updateData, thunkApi) => {
    const { token } = await thunkApi.getState().auth;
    const { data } = await api.updateFloor({
      x_token: token,
      ...updateData,
    });
    return data;
  }
);

export const getItemizers = createAsyncThunk(
  "companies/getItemizers",
  async (_, thunkApi) => {
    const { token } = await thunkApi.getState().auth;
    const { currentId } = await thunkApi.getState().companies;
    const { data } = await api.getItemizers({
      x_token: token,
      company_id: currentId,
    });
    return data;
  }
);

export const updateItemizer = createAsyncThunk(
  "companies/updateItemizer",
  async (updateData, thunkApi) => {
    const { token } = await thunkApi.getState().auth;
    const { data } = await api.updateItemizer({
      x_token: token,
      ...updateData,
    });
    return data;
  }
);

export const getCategories = createAsyncThunk(
  "companies/getCategories",
  async (_, thunkApi) => {
    const { token } = await thunkApi.getState().auth;
    const { currentId } = await thunkApi.getState().companies;
    const { data } = await api.getCategories({
      x_token: token,
      company_id: currentId,
    });
    return data;
  }
);

export const updateCategory = createAsyncThunk(
  "companies/updateCategory",
  async (updateData, thunkApi) => {
    const { token } = await thunkApi.getState().auth;
    const { data } = await api.updateCategory({
      x_token: token,
      ...updateData,
    });
    return data;
  }
);

export const getPayments = createAsyncThunk(
  "companies/getPayments",
  async (_, thunkApi) => {
    const { token } = await thunkApi.getState().auth;
    const { currentId } = await thunkApi.getState().companies;
    const { data } = await api.getPayments({
      x_token: token,
      company_id: currentId,
    });
    console.log("getPayments 1", currentId)
    return data;
  }
);

export const getCardTypes = createAsyncThunk(
  "companies/getCardTypes",
  async (_, thunkApi) => {
    const { token } = await thunkApi.getState().auth;
    const { currentId } = await thunkApi.getState().companies;
    const { data } = await api.getCardTypes({
      x_token: token,
      company_id: currentId,
    });

    return data;
  }
);

export const getHouseAccounts = createAsyncThunk(
  "companies/getHouseAccounts",
  async (_, thunkApi) => {
    const { token } = await thunkApi.getState().auth;
    const { currentId } = await thunkApi.getState().companies;
    const { data } = await api.getHouseAccount({
      x_token: token,
      company_id: currentId,
    });

    return data;
  }
);

export const getTaxes = createAsyncThunk(
  "companies/getTaxes",
  async (_, thunkApi) => {
    const { token } = await thunkApi.getState().auth;
    const { currentId } = await thunkApi.getState().companies;
    const { data } = await api.getTaxes({
      x_token: token,
      company_id: currentId,
    });
    console.log("getTaxes 1", currentId)
    return data;
  }
);

export const updatePayment = createAsyncThunk(
  "companies/updatePayment",
  async (updateData, thunkApi) => {
    const { token } = await thunkApi.getState().auth;
    const { data } = await api.updatePayment({
      x_token: token,
      ...updateData,
    });
    return data;
  }
);

export const updateTax = createAsyncThunk(
  "companies/updateTax",
  async (updateData, thunkApi) => {
    const { token } = await thunkApi.getState().auth;
    const { data } = await api.updateTax({
      x_token: token,
      ...updateData,
    });
    return data;
  }
);
export const getReports = createAsyncThunk(
  "companies/getReports",
  async (dateData, thunkApi) => {
    const { token } = await thunkApi.getState().auth;
    const { currentId } = await thunkApi.getState().companies;
    const { data } = await api.getReports({
      x_token: token,
      company_id: currentId,
      ...dateData,
    });
    //console.log(11)
    return data;
  }
);

export const getLogs = createAsyncThunk(
  "companies/getLogs",
  async (dateData, thunkApi) => {
    const { token } = await thunkApi.getState().auth;
    const { currentId } = await thunkApi.getState().companies;
    const { data } = await api.getLogs({
      x_token: token,
      company_id: currentId,
      ...dateData,
    });

    return data;
  }
);

const initialState = {
  companiesList: [],
  currentId: "",
  loading: false,
  tableLoading: false,
  cardTypesLoading: false,
  houseAccountsLoading: false,
  error: "",
  floors: [],
  itemizers: [],
  categories: [],
  payments: [],
  cardTypes: [],
  houseAccounts: [],
  taxes: [],
  logs: [],
  reports: [],
};
const pendingDataReducer = (dataName) => (state) => {
  state.tableLoading = true;
  state[dataName] = [];
};
const getDataReducer = (dataName) => (state, action) => {
  state.tableLoading = false;
  state[dataName] = action.payload;
};

const startGettingDataReducer = (dataName) => (state) => {
  state[`${dataName}Loading`] = true;
  state[dataName] = [];
};

const finishGettingDataReducer = (dataName) => (state, action) => {
  state[`${dataName}Loading`] = false;
  state[dataName] = action.payload;
};


const companiesSlice = createSlice({
  name: "companies",
  initialState,
  reducers: {
    setCompanies(state, action) {
      state.companiesList = action.payload;
    },
    setCurrentId(state, action) {
      state.currentId = action.payload;
    },
    setFloors(state, action) {
      state.floors = action.payload;
    },
    updateFloorState(state, action) {
      const newItem = action.payload;
      const oldItem = state.floors.find((i) => i.floor_id === newItem.floor_id);

      oldItem.pms_id = newItem.pms_id;
    },
    setItemizers(state, action) {
      state.itemizers = action.payload;
    },
    updateItemizerState(state, action) {
      const newItem = action.payload;
      const oldItem = state.itemizers.find((i) => i.id === newItem.id);

      oldItem.name = newItem.name;
    },
    setCategories(state, action) {
      state.categories = action.payload;
    },
    updateCategoryState(state, action) {
      const newItem = action.payload;
      const oldItem = state.categories.find(
        (i) => i.category_id === newItem.category_id
      );

      oldItem.pms_id = newItem.pms_id;
      oldItem.pms_name = newItem.pms_name;
      oldItem.pms_name1 = newItem.pms_name1;
      oldItem.pms_name2 = newItem.pms_name2;
    },
    setPayments(state, action) {
      state.payments = action.payload;
    },
    updatePaymentState(state, action) {
      const newItem = action.payload;
      const oldItem = state.payments.find(
        (i) => i.payment_id === newItem.payment_id
      );

      oldItem.pms_id = newItem.pms_id;
      oldItem.pms_account_id = newItem.pms_account_id;
      oldItem.start_date = newItem.start_date;
      oldItem.reservation_id = newItem.reservation_id;
    },

    setTaxes(state, action) {
      state.taxes = action.payload;
    },
    updateTaxState(state, action) {
      const newItem = action.payload;
      const oldItem = state.taxes.find(
        (i) => i.tax_id === newItem.tax_id
      );

      oldItem.pms_id = newItem.pms_id;
      oldItem.pms_name = newItem.pms_name;
    },
    setLogs(state, action) {
      state.logs = action.payload;
    },
    setReports(state, action) {
      state.reports = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCompanies.pending, (state) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(getCompanies.fulfilled, (state, action) => {
        state.loading = false;
        state.companiesList = action.payload;
      })
      .addCase(getCompanies.rejected, (state) => {
        state.loading = false;
        state.error = "Failed to get companies";
      })
      .addCase(getFloors.pending, pendingDataReducer("floors"))
      .addCase(getItemizers.pending, pendingDataReducer("itemizers"))
      .addCase(getCategories.pending, pendingDataReducer("categories"))
      .addCase(getPayments.pending, pendingDataReducer("payments"))
      .addCase(getCardTypes.pending, startGettingDataReducer("cardTypes"))
      .addCase(getHouseAccounts.pending, startGettingDataReducer("houseAccounts"))
      .addCase(getTaxes.pending, pendingDataReducer("taxes"))
      .addCase(getLogs.pending, pendingDataReducer("logs"))
      .addCase(getReports.pending, pendingDataReducer("reports"))
      .addCase(getFloors.fulfilled, getDataReducer("floors"))
      .addCase(getItemizers.fulfilled, getDataReducer("itemizers"))
      .addCase(getCategories.fulfilled, getDataReducer("categories"))
      .addCase(getPayments.fulfilled, getDataReducer("payments"))
      .addCase(getCardTypes.fulfilled, finishGettingDataReducer("cardTypes"))
      .addCase(getHouseAccounts.fulfilled, finishGettingDataReducer("houseAccounts"))
      .addCase(getTaxes.fulfilled, getDataReducer("taxes"))
      .addCase(getLogs.fulfilled, getDataReducer("logs"))
      .addCase(getReports.fulfilled, getDataReducer("reports"));
  },
});

export const {
  setCurrentId,
  updateFloorState,
  updateItemizerState,
  updateCategoryState,
  updatePaymentState,
  updateTaxState,
} = companiesSlice.actions;

export const companiesSelector = (state) => state.companies;
export const currentSelector = (state) => {
  const list = state.companies.companiesList;
  const currentId = state.companies.currentId;

  return {
    currentCompany: list.find((i) => i.company_id === currentId) ?? "",
    currentId,
  };
};

export default companiesSlice.reducer;
