import { useSelector } from "react-redux";
import Table from "../ui/reports/Table";
import {
  companiesSelector,
  getReports,
} from "../../redux/slices/companiesSlice";
import styled from "@emotion/styled";
import DatePicker from "../ui/reports/DatePicker";

const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;
`;

const Reports = () => {
  const { reports } = useSelector(companiesSelector);
  console.log("XXX")
  //const rows = ["RevenueCenterId", "MealPeriod", "PaymentName","itemizer_name"];
  const rows = ["Date","creationDate","CheckNumber","guest_name", "AmountWithTips"];
  const cols = ["payment_name"]
  const aggregatorName = ["Sum"];
  const vals = ['AmountWithTips'];
  const rendererName = 'Table';

  return (
    <Wrapper>
      <Table data={reports} rows={rows} cols={cols} aggregatorName={aggregatorName} vals={vals} rendererName={rendererName}  />
      <DatePicker updateAction={getReports} />
    </Wrapper>
  );
};

export default Reports;
